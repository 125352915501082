import { ErrorMessage, useResourceList, useSearchParams } from '@koopajs/react';
import { List, Typography, Grid, Chip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { InboxItem } from '../../components/InboxItem';
import { FacetLabel } from '../../components/FacetLabel';

export const Home: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { resources, errorMessage, totalCount, isProcessing } = useResourceList({
    path: '/search',
    searchParams,
    setSearchParams
  });

  const removeFilter = useCallback(
    (key: string) => {
      return () => {
        const oldFilters = searchParams.filters || [];
        const filters = oldFilters.filter((v) => v !== key);
        setSearchParams({ filters });
      };
    },
    [searchParams.filters]
  );

  return (
    <div>
      <ErrorMessage error={errorMessage} />

      <Grid container alignItems="center" justify="space-between">
        <Grid item>
          <Typography style={{ paddingTop: 30, paddingBottom: 30, fontSize: '1.5rem' }}>
            Your Messages
          </Typography>
          {searchParams.filters &&
            searchParams.filters.map((f) => (
              <Chip
                key={f}
                label={<FacetLabel label={f} />}
                onDelete={removeFilter(f)}
                style={{ paddingRight: 10 }}
              />
            ))}
        </Grid>
        <Grid item>
          {totalCount && (
            <Typography variant="body2" color="textSecondary">
              {totalCount} unread
            </Typography>
          )}
        </Grid>
      </Grid>

      <div>
        <List>
          {resources.map((r) => (
            <InboxItem key={r.id} item={r} />
          ))}

          {!isProcessing && resources.length === 0 && (
            <div style={{ textAlign: 'center', marginTop: 50 }}>
              <Typography variant="h4" color="textSecondary">
                ¯\_(ツ)_/¯
              </Typography>
              <Typography variant="h5" color="textSecondary">
                <br />
                Nothing found
              </Typography>
            </div>
          )}
        </List>
      </div>
    </div>
  );
};
