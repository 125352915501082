import React, { useCallback } from 'react';
import { Profile, useUser, useWorkspaceList } from '@koopajs/react';
import { useHistory } from 'react-router';

export const UserProfile: React.FC<{
  handleOpenWorkspaceCreation: () => void;
  handleProfileClose: () => void;
}> = ({ handleOpenWorkspaceCreation, handleProfileClose }) => {
  const { user } = useUser();
  const history = useHistory();
  const { workspaces, activeWorkspaceId, setActiveWorkspace } = useWorkspaceList();

  const handleWorkspaceSwitch = useCallback(async (workspaceId: string) => {
    const isSuccess = await setActiveWorkspace(workspaceId);
    if (isSuccess) {
      // Todo: close the modal
      history.push('/');
    }
  }, []);

  if (!user) {
    return null;
  }

  const username =
    user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  return (
    <Profile
      username={username}
      image={user.profilePicture as string}
      workspaceTitle="Your Workspaces"
      workspaceCreationTitle="Create New Workspace"
      activeWorkspaceId={activeWorkspaceId}
      workspaces={workspaces}
      onWorkspaceSwitch={handleWorkspaceSwitch}
      onWorkspaceCreation={handleOpenWorkspaceCreation}
      handleProfileClose={handleProfileClose}
    />
  );
};
