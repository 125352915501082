import React, { useCallback } from 'react';
import { ErrorMessage, useWorkspaceList } from '@koopajs/react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useForm } from 'react-hook-form';

export const WorkspaceCreate: React.FC<{
  isDialogOpen: boolean;
  handleCloseWorkspaceCreation: () => void;
}> = ({ isDialogOpen, handleCloseWorkspaceCreation }) => {
  const history = useHistory();
  const { createWorkspace, errorMessage, isProcessing } = useWorkspaceList();
  const { handleSubmit, register, errors } = useForm();

  const submit = useCallback(async (e: React.BaseSyntheticEvent): Promise<void> => {
    await handleSubmit(async (data) => {
      const isSuccess = await createWorkspace(data.name);
      if (isSuccess) {
        handleCloseWorkspaceCreation();
        history.push('/');
      }
    })(e);
  }, []);

  return (
    <Dialog open={isDialogOpen} onClose={handleCloseWorkspaceCreation} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create a workspace</DialogTitle>
      <DialogContent>
        <DialogContentText>A new workspace will let you create a new space.</DialogContentText>

        <ErrorMessage error={errorMessage} />

        <form onSubmit={submit}>
          <TextField
            autoFocus
            margin="dense"
            required
            name="name"
            label="Name"
            type="string"
            placeholder="My Company"
            inputRef={register({ required: true, minLength: 3, maxLength: 35 })}
            disabled={isProcessing}
            helperText={errors.name && 'Must be between 3 and 35 characters'}
            error={!!errors.name}
            fullWidth
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseWorkspaceCreation} color="primary" disabled={isProcessing}>
          Cancel
        </Button>
        <Button onClick={submit} color="primary" disabled={isProcessing}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
