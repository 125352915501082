import React, { useCallback } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import {
  SettingPasswordEditor,
  SettingPhotoEditor,
  SettingReadOnly,
  SettingFieldEditor,
  ErrorMessage,
  useUser,
  useFileUpload
} from '@koopajs/react';

// eslint-disable-next-line @rushstack/typedef-var
const useStyles = makeStyles({
  settingContainer: {
    paddingTop: 20,
    paddingBottom: 20
  }
});

export const Account: React.FC = () => {
  const classes = useStyles();

  const { user, setUser, errorMessage } = useUser();
  const { upload } = useFileUpload();

  const handlePasswordChange = useCallback(
    async (oldPassword: string, newPassword: string): Promise<void> => {
      console.log('handlePasswordChange', oldPassword, newPassword);
      return;
    },
    []
  );

  const handleProfileChange = useCallback(async (values: { [k: string]: unknown }): Promise<void> => {
    await setUser(values);
    return;
  }, []);

  const handlePhotoChange = useCallback(async (photo: Blob): Promise<void> => {
    const profilePicture = await upload(photo);
    return await handleProfileChange({ profilePicture });
  }, []);

  if (!user) {
    return <div>Loading</div>;
  }

  const name = user.firstname || user.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : 'Unknown';

  return (
    <div>
      <h1>My Account</h1>

      <ErrorMessage error={errorMessage} />

      <div className={classes.settingContainer}>
        <SettingPhotoEditor
          label="Profile Picture"
          description="A photo helps personalize your account."
          photoUrl={(user.profilePicture as string) || ''}
          handleValueChange={handlePhotoChange}
        />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingFieldEditor
          label="Name"
          value={name}
          description="The name attribute help personalize your product experience. It is visible to people in your organization only."
          fields={[
            { key: 'firstname', label: 'Firstname' },
            { key: 'lastname', label: 'Lastname' }
          ]}
          defaultValues={user}
          handleValueChange={handleProfileChange}
        />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingPasswordEditor
          description="Choose a strong and unique password to secure your account."
          handleValueChange={handlePasswordChange}
        />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingReadOnly label="Email Address" value={(user.preferredEmail as string) || 'Unknown'} />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingReadOnly label="Account ID" value={`u:${user.id}`} />
      </div>
    </div>
  );
};
