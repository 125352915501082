import { useUser, ErrorMessage } from '@koopajs/react';
import { Button, Typography } from '@material-ui/core';
import React, { useCallback } from 'react';

export interface IContentSuccessProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentSuccess: React.FC<IContentSuccessProps> = (props: IContentSuccessProps) => {
  const { movePrevious } = props;

  const { setUser, isProcessing, errorMessage } = useUser();

  const onSubmit = useCallback(async (): Promise<void> => {
    await setUser({
      activatedAt: new Date().toISOString()
    });
  }, []);

  return (
    <div>
      <Typography>That&apos;s it! Your account is ready to be use.</Typography>

      <ErrorMessage error={errorMessage} />

      <div>
        <div>
          <div>
            <Button onClick={movePrevious} disabled={isProcessing}>
              Back
            </Button>
            <Button variant="contained" color="primary" disabled={isProcessing} onClick={onSubmit}>
              Open App
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
