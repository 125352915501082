import React, { useCallback } from 'react';
import {
  AppBar,
  useResourceList,
  useSearchParams,
  useUser,
  useWorkspace,
  useComponentVisibility
} from '@koopajs/react';
import { Logo } from '../Logo';
import { Switch, Route } from 'react-router-dom';
import * as Pages from '../../pages';
import { UserProfile } from '../UserProfile';
import { Onboarding } from '../Onboarding';
import { WorkspaceCreate } from '../WorkspaceCreation';
import { Facets } from '../Facets';
import s from './style.module.scss';

export const App: React.FC = () => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const workspaceCreation = useComponentVisibility('workspaceCreation');
  const userMenu = useComponentVisibility('userMenu');
  const { isProcessing } = useResourceList({ path: '/search', useCache: true });
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearch = useCallback((query: string) => {
    setSearchParams({ query });
  }, []);

  return (
    <Onboarding>
      <AppBar
        componentMenuUser={
          <UserProfile
            handleOpenWorkspaceCreation={workspaceCreation.setVisible}
            handleProfileClose={userMenu.setHidden}
          />
        }
        componentLogo={<Logo />}
        componentMenuLeft={<Facets />}
        appIsLoading={isProcessing}
        appName="Koopa Phone"
        userAvatar={user?.profilePicture}
        userHeadings={[`${user?.firstname} ${user?.lastname}`, workspace?.name as string]}
        searchBarPlaceholder="Search messages, voicemails..."
        searchBarDisabled={isProcessing}
        searchBarActiveQuery={searchParams.query || ''}
        onSearch={handleSearch}
      >
        <div className={s.container}>
          <Switch>
            <Route path="/" exact component={Pages.Home} />
            <Route path="/settings" component={Pages.Settings} />
            <Route path="/account" component={Pages.Account} />
            <Route path="*" component={Pages.NotFound} />
          </Switch>
        </div>
      </AppBar>
      <WorkspaceCreate
        handleCloseWorkspaceCreation={workspaceCreation.setHidden}
        isDialogOpen={workspaceCreation.isVisible}
      />
    </Onboarding>
  );
};
