import { useSearchParams, useResourceList } from '@koopajs/react';
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { useCallback } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FacetLabel } from '../FacetLabel';

export const Facets: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const addFacetToSearch = useCallback(
    (key: string, value: string) => {
      return () => {
        const filters = searchParams.filters || [];
        filters.push(`${key}:"${value}"`);
        setSearchParams({ filters });
      };
    },
    [searchParams.filters]
  );

  const handleObjectTypeChange = useCallback(
    (event: unknown, value: string) => {
      const oldFilters = searchParams.filters || [];
      const filters = oldFilters.filter((f) => !f.startsWith('_index'));
      if (value !== '*') {
        filters.push(`_index:"${value}"`);
      }
      setSearchParams({ filters });
    },
    [searchParams.filters]
  );

  const objectType =
    searchParams.filters
      ?.find((f) => f.startsWith('_index'))
      ?.replace('_index:', '')
      .replace(/"/g, '') || '*';

  const { facets, isProcessing } = useResourceList({
    path: '/search',
    searchParams,
    useCache: true
  });

  return (
    <div style={{ marginTop: 50 }}>
      {facets && (
        <div>
          <FormControl component="fieldset" style={{ padding: 16 }}>
            <FormLabel component="legend">Type</FormLabel>
            <RadioGroup value={objectType} onChange={handleObjectTypeChange}>
              <FormControlLabel value="*" control={<Radio />} label="All" />
              <FormControlLabel value="phone--sync_sms" control={<Radio />} label="SMS" />
              <FormControlLabel value="phone--sync_call" control={<Radio />} label="Calls" />
            </RadioGroup>
          </FormControl>

          {facets.map((f) => (
            <>
              {f.values.length > 1 && (
                <Accordion elevation={0} key={f.key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" color="primary">
                      <FacetLabel label={f.key} />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div style={{ width: '100%' }}>
                      {f.values.map((v) => (
                        <Button
                          key={v.key}
                          onClick={addFacetToSearch(f.key, v.key)}
                          fullWidth
                          disabled={isProcessing}
                        >
                          <Grid container alignItems="center" justify="space-between">
                            <Grid item>{v.key}</Grid>
                            <Grid item>
                              <Typography variant="caption" color="textSecondary">
                                {v.count}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
};
