import React from 'react';
import { Button, ButtonGroup, Typography, makeStyles } from '@material-ui/core';
import { Link, Switch, Route, useLocation } from 'react-router-dom';
import c from 'classnames';
import { SettingsGeneral } from './pages/general';
import { SettingsPermissions } from './pages/permissions';
import { SettingsIntegrations } from './pages/integrations';
import { SettingsSystemLogs } from './pages/systemLogs';
import { SettingsVoice } from './pages/voice';

// eslint-disable-next-line @rushstack/typedef-var
const useStyles = makeStyles((theme) => ({
  tabs: {
    marginBottom: 20
  },
  tab: {
    border: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: '1px solid transparent',
    padding: '5px 2px',
    marginRight: theme.spacing(2),
    textTransform: 'inherit'
  },
  active: {
    color: theme.palette.primary.main,
    borderBottomColor: theme.palette.primary.main
  }
}));

export const Settings: React.FC = () => {
  const styles = useStyles();
  const activePage = useLocation().pathname.split('/')[2];
  const isActivePage = (page?: string): boolean => {
    return page === activePage;
  };

  return (
    <div>
      <Typography variant="h1" style={{ fontSize: 24 }}>
        Settings
      </Typography>

      <ButtonGroup className={styles.tabs}>
        <Button
          className={c(styles.tab, { [styles.active]: isActivePage() })}
          component={Link}
          to="/settings"
        >
          General
        </Button>
        <Button
          className={c(styles.tab, { [styles.active]: isActivePage('voice') })}
          component={Link}
          to="/settings/voice"
        >
          Voice
        </Button>
        <Button
          className={c(styles.tab, { [styles.active]: isActivePage('permissions') })}
          component={Link}
          to="/settings/permissions"
        >
          Users &amp; Permissions
        </Button>
        <Button
          className={c(styles.tab, { [styles.active]: isActivePage('integrations') })}
          component={Link}
          to="/settings/integrations"
        >
          Integrations
        </Button>
        <Button
          className={c(styles.tab, { [styles.active]: isActivePage('system-logs') })}
          component={Link}
          to="/settings/system-logs"
        >
          System Logs
        </Button>
      </ButtonGroup>

      <Switch>
        <Route path="/settings/permissions" component={SettingsPermissions} />
        <Route path="/settings/integrations" component={SettingsIntegrations} />
        <Route path="/settings/system-logs" component={SettingsSystemLogs} />
        <Route path="/settings/voice" component={SettingsVoice} />
        <Route path="/settings" component={SettingsGeneral} />
      </Switch>
    </div>
  );
};
