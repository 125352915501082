import React, { useCallback } from 'react';
import {
  SettingPhotoEditor,
  SettingReadOnly,
  SettingFieldEditor,
  ErrorMessage,
  useWorkspace
} from '@koopajs/react';
import { Divider, makeStyles } from '@material-ui/core';

// eslint-disable-next-line @rushstack/typedef-var
const useStyles = makeStyles({
  settingContainer: {
    paddingTop: 20,
    paddingBottom: 20
  }
});

export const SettingsGeneral: React.FC = () => {
  const classes = useStyles();
  const { workspace, setWorkspace, errorMessage } = useWorkspace();

  const handleProfileChange = useCallback(async (values: { [k: string]: unknown }): Promise<void> => {
    await setWorkspace(values);
    return;
  }, []);

  const handlePhotoChange = useCallback(async (photo: Blob): Promise<void> => {
    // TODO: Upload the photo to S3
    return await handleProfileChange({ icon: 'path' });
  }, []);

  if (!workspace) {
    return null;
  }

  return (
    <div>
      <ErrorMessage error={errorMessage} />

      <div className={classes.settingContainer}>
        <SettingPhotoEditor
          label="Workspace Icon"
          description="An icon helps personalize your workspace."
          photoUrl=""
          handleValueChange={handlePhotoChange}
        />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingFieldEditor
          label="Name"
          value={workspace.name || 'Unknown'}
          description="The name help personalize your product experience."
          fields={[{ key: 'name', label: 'Name' }]}
          defaultValues={workspace}
          handleValueChange={handleProfileChange}
        />
      </div>

      <Divider />

      <div className={classes.settingContainer}>
        <SettingReadOnly label="Workspace ID" value={`w:${workspace.id}`} />
      </div>
    </div>
  );
};
