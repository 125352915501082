import { createMuiTheme } from '@material-ui/core';
import './theme.css';

// eslint-disable-next-line @rushstack/typedef-var
export const theme = createMuiTheme({
  /**
   * Application Theme
   * https://material-ui.com/customization/palette/
   */
  palette: {
    primary: {
      main: '#388e3c'
    },
    secondary: {
      main: '#ff8a65'
    }
  },
  typography: {
    fontFamily: ['Montserrat'].join(',')
  }
});
