import React, { useCallback } from 'react';
import { SettingFieldEditor, ErrorMessage, useWorkspace } from '@koopajs/react';
import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line @rushstack/typedef-var
const useStyles = makeStyles({
  settingContainer: {
    paddingTop: 20,
    paddingBottom: 20
  }
});

export const SettingsVoice: React.FC = () => {
  const classes = useStyles();
  const { workspace, setWorkspace, errorMessage } = useWorkspace();

  const handleProfileChange = useCallback(async (values: { [k: string]: unknown }): Promise<void> => {
    await setWorkspace(values);
    return;
  }, []);

  if (!workspace) {
    return null;
  }

  return (
    <div>
      <ErrorMessage error={errorMessage} />

      <div className={classes.settingContainer}>
        <SettingFieldEditor
          label="Voicemail Message"
          value={(workspace.voicemailMessage as string) || 'Please leave a message at the beep.'}
          description="Personalize the voicemail message your callers hear"
          fields={[{ key: 'voicemailMessage', label: 'Message' }]}
          defaultValues={workspace}
          handleValueChange={handleProfileChange}
        />
      </div>
    </div>
  );
};
