import { InitState, Plugins } from '@koopajs/app';
import { AppProvider } from '@koopajs/react';
import { Amplify } from '@koopajs/plugins';
import React from 'react';
import ReactDom from 'react-dom';

import { Auth } from './components/Auth';
import { App } from './components/App';
import { AppSkeleton } from './components/AppSkeleton';
import { theme } from './theme';
import * as sw from './swRegistration';

Plugins.Register(new Amplify());
//Extensions.Register(new Sentry());
//Extensions.Register(new Amplitude());
//Extensions.Register(new SplitIO());
//Extensions.Register(new LogRocket());
//Extensions.Register(new GoogleAnalytics());
//Extensions.Register(new Mixpanel());

// eslint-disable-next-line @typescript-eslint/no-floating-promises
InitState();

ReactDom.render(
  <React.StrictMode>
    <AppProvider componentAuthentication={Auth} componentLoading={AppSkeleton} muiTheme={theme}>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

sw.register();
