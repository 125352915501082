import React from 'react';
export const FacetLabel: React.FC<{ label: string }> = ({ label }) => {
  const [key, tempValue] = label.split(':');
  const value = tempValue && tempValue.replace(/"/g, '');

  const facetLabel: { [k: string]: string } = {
    'from.keyword': 'Caller',
    'to.keyword': 'Destination',
    'phone--sync_call': 'Calls only',
    'phone--sync_sms': 'SMS only'
  };

  if (key === '_index') {
    return <>{facetLabel[value] || value}</>;
  }

  return (
    <>
      {facetLabel[key] || key}
      {value && <> {value}</>}
    </>
  );
};
