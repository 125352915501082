import BodyClassName from 'react-body-classname';
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';

export const Auth: React.FC = () => {
  return (
    <BodyClassName className="page--login">
      <>
        <AmplifyAuthenticator usernameAlias="email">
          <AmplifySignUp
            slot="sign-up"
            usernameAlias="email"
            formFields={[
              {
                type: 'email',
                label: 'Email Address',
                placeholder: 'Enter your email address',
                required: true
              },
              {
                type: 'password',
                label: 'Password',
                placeholder: 'Enter your password',
                required: true
              }
            ]}
          />
          <AmplifySignIn slot="sign-in" usernameAlias="email" />
        </AmplifyAuthenticator>
      </>
    </BodyClassName>
  );
};
