import { Operations } from '@koopajs/app';
import { useWorkspace, ErrorMessage } from '@koopajs/react';
import { Button, Typography, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles({
  inputWrapper: {
    marginTop: 10,
    marginBottom: 10
  }
});

interface IFormData {
  name: string;
}

export interface IContentWorkspaceProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentWorkspace: React.FC<IContentWorkspaceProps> = (props: IContentWorkspaceProps) => {
  const { moveNext, movePrevious } = props;

  const style = useStyle();
  const { workspace, setWorkspace, isProcessing, errorMessage } = useWorkspace();
  const createWorkspaceOperation = Operations.Workspaces.create(useDispatch());
  const { handleSubmit, register, errors } = useForm<IFormData>({
    defaultValues: {
      name: workspace?.name
    }
  });

  const onSubmit = async ({ name }: { name: string }): Promise<void> => {
    const isSuccessful = workspace?.id ? await setWorkspace({ name }) : await createWorkspaceOperation(name);

    if (isSuccessful) {
      moveNext();
    }
  };

  return (
    <div>
      <Typography>Workspaces help you collaborate securely with your team.</Typography>

      <ErrorMessage error={errorMessage} />

      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style.inputWrapper}>
              <TextField
                error={!!errors.name}
                helperText={errors.name && 'Needs to be between 2 and 50 characters'}
                name="name"
                variant="outlined"
                fullWidth
                label="Workspace Name"
                disabled={isProcessing}
                inputRef={register({ required: true, maxLength: 50, minLength: 2 })}
              />
            </div>

            <div>
              <Button onClick={movePrevious} disabled={isProcessing}>
                Back
              </Button>
              <Button variant="contained" color="primary" disabled={isProcessing} type="submit">
                Next
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
