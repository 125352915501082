import React from 'react';
import { Date } from '@koopajs/react';
import { Divider, Avatar, Typography, ListItem, ListItemAvatar, ListItemText, Grid } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import SmsIcon from '@material-ui/icons/Sms';

export interface IInboxItemProps {
  item: { [k: string]: unknown };
}

export const InboxItem: React.FC<IInboxItemProps> = (props: IInboxItemProps) => {
  const { item } = props;

  let body;
  let icon;
  if (item.$model === 'sync_sms') {
    body = (
      <Typography component="span" variant="body1" color="textPrimary">
        {item.body as string}
      </Typography>
    );
    icon = <SmsIcon />;
  } else if (item.$model === 'sync_call') {
    icon = <PhoneIcon />;
    if (item.voicemailPath) {
      body = (
        <audio controls controlsList="Volume">
          <source src={item.voicemailPath as string} type="audio/mpeg" />
        </audio>
      );
    } else {
      body = (
        <Typography component="span" variant="body1" color="textPrimary">
          No voicemail left.
        </Typography>
      );
    }
  }

  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="body2" color="primary">
                    {item.from as string}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" color="textSecondary">
                    <Date isoDate={item.$createdAt as string} />
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          }
          secondary={<React.Fragment>{body}</React.Fragment>}
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};
