import { useResourceList, JsonViewer } from '@koopajs/react';
import React, { useCallback, useState } from 'react';
import {
  IconButton,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListSubheader,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  ListItemIcon,
  Typography
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import { Api } from '@koopajs/app';

export const SettingsIntegrations: React.FC = () => {
  const [addConnectionElement, setAddConnectionElement] = useState(null);

  const integrations = useResourceList({ path: '/integrations' });
  const connections = useResourceList({ path: '/connections' });
  const providers = useResourceList({ path: '/providers' });
  const integrationTypes = useResourceList({ path: '/integration-types' });

  const handleClick = useCallback((event): void => {
    setAddConnectionElement(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAddConnectionElement(null);
  }, []);

  const handleConnectionCreation = (providerType: string): (() => void) => {
    return async () => {
      const {
        data: { redirect }
      } = await Api.client.get(`/providers/${providerType}/authorize`);
      if (redirect) {
        const height = 450;
        const width = 450;
        const left = screen.width / 2 - width / 2;
        const top = screen.height / 2 - height / 2;
        window.open(
          redirect,
          'sharer',
          `toolbar=0,resizable=0,copyhistory=0,status=0,top=${top},left=${left},width=${width},height=${height}`
        );
      }
    };
  };

  return (
    <div>
      <List
        component={Paper}
        subheader={
          <ListSubheader>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>Integrations</Grid>
              <Grid item>
                <Button startIcon={<AddIcon />} size="small" onClick={handleClick}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        {integrations.resources.map((row) => (
          <ListItem key={row.id} button>
            <ListItemAvatar>
              <Avatar src="https://todoist.com/app/manifest_icons/icon_180x180.d0c3ef3c6be29a9ca57ead5171bbebc4.png" />
            </ListItemAvatar>

            <ListItemText primary={row.label as string} secondary={row.provider as string} />
            <ListItemSecondaryAction>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>

      <hr />

      <List
        component={Paper}
        subheader={
          <ListSubheader>
            <Grid container direction="row" justify="space-between" alignItems="center">
              <Grid item>Connections</Grid>
              <Grid item>
                <Button startIcon={<AddIcon />} size="small" onClick={handleClick}>
                  Add
                </Button>
              </Grid>
            </Grid>
          </ListSubheader>
        }
      >
        {connections.resources.map((connection) => (
          <ListItem key={connection.id} button>
            <ListItemAvatar>
              <Avatar src="https://todoist.com/app/manifest_icons/icon_180x180.d0c3ef3c6be29a9ca57ead5171bbebc4.png" />
            </ListItemAvatar>

            <ListItemText primary={connection.label as string} secondary={connection.provider as string} />
            <ListItemSecondaryAction>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Menu
        id="simple-menu"
        anchorEl={addConnectionElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        open={Boolean(addConnectionElement)}
        onClose={handleClose}
      >
        {providers.resources.map((provider) => (
          <MenuItem key={provider.id} onClick={handleConnectionCreation(provider.key as string)}>
            <ListItemIcon>
              <Avatar style={{ width: 25, height: 25 }} src={provider.icon as string} />
            </ListItemIcon>
            <Typography variant="inherit">Connect {provider.label as string}</Typography>
          </MenuItem>
        ))}
      </Menu>

      <hr />

      <JsonViewer
        json={{
          integrationTypes,
          integrations,
          providers,
          connections
        }}
      />
    </div>
  );
};
