import React, { useCallback, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Paper,
  IconButton,
  List,
  ListSubheader,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ErrorMessage, useResourceList, Date } from '@koopajs/react';

import { useForm } from 'react-hook-form';

export const SettingsPermissions: React.FC = () => {
  const [isInvitationVisible, setIsInvitationVisible] = useState(false);
  const invitations = useResourceList({ path: '/invitations' });
  const users = useResourceList({ path: '/users' });
  const { handleSubmit, register, errors, reset } = useForm();

  const showInvitationDialog = useCallback(() => {
    setIsInvitationVisible(true);
  }, []);

  const hideInvitationDialog = useCallback(() => {
    setIsInvitationVisible(false);
    reset();
  }, []);

  const submit = useCallback(async (e: React.BaseSyntheticEvent): Promise<void> => {
    await handleSubmit(async (data) => {
      const isSuccess = await invitations.createResource(data);
      if (isSuccess) {
        hideInvitationDialog();
      }
    })(e);
  }, []);

  return (
    <div>
      <Paper>
        <List
          subheader={
            <ListSubheader>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>Users</Grid>
                <Grid item>
                  <Button startIcon={<AddIcon />} size="small" onClick={showInvitationDialog}>
                    Invite new user
                  </Button>
                </Grid>
              </Grid>
            </ListSubheader>
          }
        >
          {users.resources.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={`https://gsd.koopa.cloud/users/${user.id}/images`} />
              </ListItemAvatar>

              <ListItemText primary={user.label as string} secondary={user.preferredEmail as string} />
              <ListItemSecondaryAction>
                <IconButton>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}

          {invitations.resources && invitations.resources.length > 0 && (
            <>
              <ListSubheader>Pending invitations</ListSubheader>

              {invitations.resources.map((invitation) => (
                <ListItem key={invitation.id}>
                  <Chip label={invitation.email as string} /> &nbsp; Expires{' '}
                  <Date isoDate={invitation.expiredAt as string} />
                </ListItem>
              ))}
            </>
          )}
        </List>
      </Paper>

      <Dialog open={isInvitationVisible} onClose={hideInvitationDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Invite a user to your workspace</DialogTitle>
        <DialogContent>
          <DialogContentText>This user will have basic permissions</DialogContentText>

          <ErrorMessage error={invitations.errorMessage} />

          <form onSubmit={submit}>
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              placeholder="james@company.com"
              inputRef={register({ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })}
              disabled={invitations.isProcessing}
              helperText={errors.email && 'Must be a valid email address'}
              error={!!errors.email}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideInvitationDialog} color="primary" disabled={invitations.isProcessing}>
            Cancel
          </Button>
          <Button onClick={submit} color="primary" disabled={invitations.isProcessing}>
            Send Invitation
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
