import { useUser, ErrorMessage } from '@koopajs/react';
import { Button, Typography, makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useCallback, useState } from 'react';

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles({
  inputWrapper: {
    marginTop: 10,
    marginBottom: 10
  },
  toc: {
    fontSize: 10,
    maxHeight: 200,
    overflowY: 'scroll',
    overflowX: 'hidden',
    border: '1px solid #333',
    padding: 10
  }
});

interface IFormData {
  firstname: string;
  lastname: string;
}

export interface IContentTOCProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentTOC: React.FC<IContentTOCProps> = (props: IContentTOCProps) => {
  const { moveNext } = props;

  const style = useStyle();
  const { user, setUser, isProcessing, errorMessage } = useUser();
  const [isAccepted, setIsAccepted] = useState(!!user?.latestAcceptedTerms);

  const onSubmit = useCallback(async (): Promise<void> => {
    const isSuccessful = await setUser({
      latestAcceptedTerms: '2021-04-15'
    });

    if (isSuccessful) {
      moveNext();
    }
  }, []);

  const handleChange = useCallback(() => setIsAccepted((v) => !v), []);

  return (
    <div>
      <Typography>Please accept the Terms & Conditions.</Typography>

      <ErrorMessage error={errorMessage} />

      <div>
        <div>
          <div className={style.toc}>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vulputate augue urna, ut auctor
              urna commodo non. Integer lectus ipsum, gravida ut scelerisque vitae, pellentesque in mi. Proin
              tempor augue tincidunt, dapibus odio vel, laoreet sapien. Vestibulum rutrum magna id magna
              maximus ornare. Donec aliquam nec magna eu finibus. Vestibulum ante ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Nulla feugiat dui sit amet consectetur bibendum.
              Cras sem mi, efficitur a ultricies ut, luctus et ipsum. Proin odio dolor, ullamcorper nec nibh
              in, interdum viverra lorem. Aliquam a semper diam, in mattis sem. Nullam fringilla sed enim a
              tempus. Morbi condimentum justo ligula, cursus elementum eros cursus vitae. Curabitur eu
              malesuada dui. Donec aliquam imperdiet laoreet.
            </p>
            <p>
              Etiam vitae tincidunt eros. Phasellus rutrum lacinia est, eu tempor tellus pulvinar et.
              Curabitur vestibulum enim erat, sit amet luctus mi dignissim quis. Nunc nunc risus, tincidunt
              vel tellus sit amet, euismod eleifend nulla. Integer sit amet tellus velit. Aliquam erat
              volutpat. Nam pretium ante at sapien dapibus porttitor. Vestibulum ante ipsum primis in faucibus
              orci luctus et ultrices posuere cubilia curae; Vivamus condimentum mi eu leo gravida eleifend.
            </p>
            <p>
              Sed eget vulputate est. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum
              ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vivamus faucibus,
              purus at lobortis gravida, lectus mi tincidunt magna, a feugiat ligula nisi sed erat. Donec
              eleifend, libero sit amet mollis egestas, ex turpis dignissim massa, nec condimentum nulla mi
              non est. Sed sed fringilla enim, eget viverra turpis. Sed id magna aliquet, efficitur ligula at,
              consectetur lectus. Donec lobortis risus at purus laoreet pulvinar. Praesent hendrerit ligula in
              eros blandit, sit amet viverra diam posuere. Vestibulum commodo, ligula a euismod egestas,
              turpis diam congue sem, pharetra placerat nisi turpis ultrices magna. Mauris hendrerit turpis
              justo, quis vulputate risus semper non. Sed pellentesque interdum dui vel laoreet. Sed imperdiet
              risus at sapien cursus mattis in ac dolor. Sed euismod sit amet lectus quis ultrices. Nullam
              tempor ipsum eget diam molestie, quis ornare nisi tincidunt.
            </p>
            <p>
              Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
              Phasellus vehicula elit a elementum aliquam. Sed finibus pulvinar congue. Cras posuere iaculis
              elit in venenatis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur
              ridiculus mus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
              himenaeos. Etiam eget turpis viverra, tristique risus in, ornare velit.
            </p>
            <p>
              Proin quis ultrices nibh, ac porta tortor. Mauris aliquet urna vel imperdiet iaculis. Fusce
              vitae nibh sit amet libero vehicula feugiat sed ut augue. Nunc hendrerit ultrices ex, eget
              cursus lorem. Cras vitae ante nibh. Orci varius natoque penatibus et magnis dis parturient
              montes, nascetur ridiculus mus. Etiam bibendum mauris elit, nec commodo nulla pharetra a.
              Vestibulum mattis velit ante. Nulla facilisi. Etiam sodales efficitur dolor eget molestie. Nunc
              pretium neque elit, a cursus ipsum dapibus vel. Vestibulum ante ipsum primis in faucibus orci
              luctus et ultrices posuere cubilia curae; Vestibulum ante ipsum primis in faucibus orci luctus
              et ultrices posuere cubilia curae;
            </p>
          </div>

          <div className={style.inputWrapper}>
            <FormControlLabel
              control={<Checkbox color="primary" checked={isAccepted} onChange={handleChange} />}
              label="I have read and I accept the Terms & Conditions"
            />
          </div>

          <div>
            <Button disabled>Back</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!isAccepted || isProcessing}
              onClick={onSubmit}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
