import { useUser, ErrorMessage } from '@koopajs/react';
import { Button, Typography, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles({
  inputWrapper: {
    marginTop: 10,
    marginBottom: 10
  }
});

interface IFormData {
  firstname: string;
  lastname: string;
}

export interface IContentNameProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentName: React.FC<IContentNameProps> = (props: IContentNameProps) => {
  const { moveNext, movePrevious } = props;

  const style = useStyle();
  const { user, setUser, isProcessing, errorMessage } = useUser();
  const { handleSubmit, register, errors } = useForm<IFormData>({
    defaultValues: {
      firstname: user?.firstname,
      lastname: user?.lastname
    }
  });

  const onSubmit = async (data: object): Promise<void> => {
    const isSuccessful = await setUser(data);

    if (isSuccessful) {
      moveNext();
    }
  };

  return (
    <div>
      <Typography>This helps personalized your product experience.</Typography>

      <ErrorMessage error={errorMessage} />

      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={style.inputWrapper}>
              <TextField
                error={!!errors.firstname}
                helperText={errors.firstname && 'Needs to be between 2 and 50 characters'}
                name="firstname"
                variant="outlined"
                fullWidth
                label="Firstname"
                disabled={isProcessing}
                inputRef={register({ required: true, maxLength: 50, minLength: 2 })}
              />
            </div>

            <div className={style.inputWrapper}>
              <TextField
                error={!!errors.lastname}
                helperText={errors.lastname && 'Needs to be between 2 and 50 characters'}
                name="lastname"
                variant="outlined"
                fullWidth
                label="Lastname"
                disabled={isProcessing}
                inputRef={register({ required: true, maxLength: 50, minLength: 2 })}
              />
            </div>

            <div>
              <Button onClick={movePrevious} disabled={isProcessing}>
                Back
              </Button>
              <Button variant="contained" color="primary" disabled={isProcessing} type="submit">
                Next
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
