import { useUser, useWorkspace, useWorkspaceList } from '@koopajs/react';
import { Stepper, Step, StepContent, StepLabel, Typography, Paper, makeStyles } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useQueryParams, StringParam } from 'use-query-params';
import { ContentTOC } from './Steps/ContentTOC';
import { ContentName } from './Steps/ContentName';
import { ContentAvatar } from './Steps/ContentAvatar';
import { ContentWorkspace } from './Steps/ContentWorkspace';
import { ContentSuccess } from './Steps/ContentSuccess';
import { ContentInvitation } from './Steps/ContentInvitation';

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles({
  container: {
    width: '100%',
    maxWidth: 800,
    margin: '20px auto',
    padding: 20
  }
});

export const Onboarding: React.FC<React.PropsWithChildren<{}>> = (props: React.PropsWithChildren<{}>) => {
  const style = useStyle();
  const { user } = useUser();
  const { workspaces } = useWorkspaceList();
  const { workspace } = useWorkspace();
  const [{ invitationToken }, setQuery] = useQueryParams({ invitationToken: StringParam });

  const [step, setStep] = useState(0);
  const moveNext = useCallback(() => {
    setStep((s) => s + 1);
  }, []);

  const movePrevious = useCallback(() => {
    setStep((s) => s - 1);
  }, []);

  const handleRemoveInvitationCode = useCallback(() => {
    setQuery({ invitationToken: undefined });
  }, []);

  if (!user) {
    return null;
  }

  const isOnboardingRequired = (): boolean => {
    if (!user.activatedAt || workspaces.length === 0 || !workspace?.name) {
      return true;
    }

    return false;
  };

  if (isOnboardingRequired()) {
    return (
      <Paper className={style.container} elevation={1}>
        <div style={{ marginTop: 40, marginBottom: 10 }}>
          <Typography variant="h3">Welcome to Koopa Phone.</Typography>
          <Typography variant="subtitle1">
            Koopa Phone is the leading cloud communication platform for international travelers and digital
            nomad.
          </Typography>
        </div>

        <Stepper activeStep={step} orientation="vertical">
          <Step>
            <StepLabel>Terms and Conditions</StepLabel>
            <StepContent>
              <ContentTOC moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Name</StepLabel>
            <StepContent>
              <ContentName moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Picture </StepLabel>
            <StepContent>
              <ContentAvatar moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Your Workspace</StepLabel>
            <StepContent>
              <ContentWorkspace moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>

          <Step>
            <StepLabel>Ready!</StepLabel>
            <StepContent>
              <ContentSuccess moveNext={moveNext} movePrevious={movePrevious} />
            </StepContent>
          </Step>
        </Stepper>
      </Paper>
    );
  }

  if (invitationToken) {
    return (
      <ContentInvitation
        invitationToken={invitationToken}
        handleRemoveInvitationCode={handleRemoveInvitationCode}
      />
    );
  }

  return <>{props.children}</>;
};
