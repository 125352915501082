import PermPhoneMsgRoundedIcon from '@material-ui/icons/PermPhoneMsgRounded';

export const Logo: React.FC = () => {
  return (
    <>
      <PermPhoneMsgRoundedIcon />
      <span style={{ padding: 10, textTransform: 'initial' }}>Koopa Phone</span>
    </>
  );
};
