import { useUser, ErrorMessage } from '@koopajs/react';
import { Grid, Avatar, Button, makeStyles, Typography } from '@material-ui/core';
import cc from 'classnames';
import React, { useCallback, useState } from 'react';

const avatars: string[] = [
  'https://gsd.koopa-cdn.net/general/10.png',
  'https://gsd.koopa-cdn.net/general/01.png',
  'https://gsd.koopa-cdn.net/general/02.png',
  'https://gsd.koopa-cdn.net/general/03.png',
  'https://gsd.koopa-cdn.net/general/04.png',
  'https://gsd.koopa-cdn.net/general/05.png',
  'https://gsd.koopa-cdn.net/general/19.png',
  'https://gsd.koopa-cdn.net/general/07.png',
  'https://gsd.koopa-cdn.net/general/08.png',
  'https://gsd.koopa-cdn.net/general/09.png',
  'https://gsd.koopa-cdn.net/general/11.png',
  'https://gsd.koopa-cdn.net/general/12.png',
  'https://gsd.koopa-cdn.net/general/13.png',
  'https://gsd.koopa-cdn.net/general/14.png',
  'https://gsd.koopa-cdn.net/general/15.png',
  'https://gsd.koopa-cdn.net/general/16.png',
  'https://gsd.koopa-cdn.net/general/17.png',
  'https://gsd.koopa-cdn.net/general/18.png'
];

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles((theme) => ({
  profilePicture: {
    width: 75,
    height: 75,
    border: '2px solid transparent',
    cursor: 'pointer',
    opacity: 0.4
  },
  profilePictureSelected: {
    borderColor: theme.palette.secondary.main,
    opacity: 1
  }
}));

interface IFormData {
  firstname: string;
  lastname: string;
}

export interface IContentAvatarProps {
  moveNext: () => void;
  movePrevious: () => void;
}

export const ContentAvatar: React.FC<IContentAvatarProps> = (props: IContentAvatarProps) => {
  const { moveNext, movePrevious } = props;

  const style = useStyle();
  const { user, setUser, isProcessing, errorMessage } = useUser();

  const [profilePicture, setProfilePicture] = useState(user?.profilePicture || avatars[0]);

  const onSubmit = useCallback(async (): Promise<void> => {
    const isSuccessful = await setUser({ profilePicture });

    if (isSuccessful) {
      moveNext();
    }
  }, [profilePicture]);

  const setAvatar = (file: string): (() => void) => {
    return () => setProfilePicture(file);
  };

  return (
    <div>
      <Typography>Select a picture that best represent you, or upload your own avatar.</Typography>

      <ErrorMessage error={errorMessage} />

      <div>
        <div>
          <Grid style={{ margin: '40px 20px' }} container justify="space-between" alignItems="center">
            {avatars.map((file) => (
              <Grid item xs={2} key={file}>
                <Avatar
                  variant="circular"
                  className={cc(style.profilePicture, {
                    [style.profilePictureSelected]: file === profilePicture
                  })}
                  src={file}
                  onClick={setAvatar(file)}
                />
              </Grid>
            ))}
          </Grid>

          <div>
            <Button onClick={movePrevious} disabled={isProcessing}>
              Back
            </Button>
            <Button variant="contained" color="primary" disabled={isProcessing} onClick={onSubmit}>
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
