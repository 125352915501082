import { useResource } from '@koopajs/react';
import { Button, Typography, makeStyles, withStyles, Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback } from 'react';
import { green } from '@material-ui/core/colors';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

// eslint-disable-next-line @rushstack/typedef-var
const useStyle = makeStyles({
  pageWrapper: {
    maxWidth: 450,
    margin: '40px auto 0 auto',
    textAlign: 'center'
  },
  icon: {
    fontSize: 75,
    marginBottom: 25
  },
  separator: {
    marginTop: 25,
    marginBottom: 10
  }
});

// eslint-disable-next-line @rushstack/typedef-var
const SuccessButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

export interface IContentInvitationProps {
  invitationToken: string;
  handleRemoveInvitationCode: () => void;
}

export const ContentInvitation: React.FC<IContentInvitationProps> = (props: IContentInvitationProps) => {
  const style = useStyle();
  const { resource, errorMessage, isProcessing } = useResource({
    path: 'invitations',
    id: props.invitationToken
  });

  const handleBackToHomeScreen = useCallback(() => {
    props.handleRemoveInvitationCode();
  }, []);

  return (
    <div className={style.pageWrapper}>
      <div>
        <VerifiedUserIcon className={style.icon} />
      </div>

      {isProcessing && (
        <>
          <Typography>
            <Skeleton />
          </Typography>

          <Divider className={style.separator} />

          <div>
            <Button disabled={isProcessing} color="primary">
              Back to home
            </Button>
          </div>
        </>
      )}

      {errorMessage && (
        <>
          <Typography>The invitation is expired or invalid.</Typography>

          <Divider className={style.separator} />

          <div>
            <Button disabled={isProcessing} color="primary" onClick={handleBackToHomeScreen}>
              Back to home
            </Button>
          </div>
        </>
      )}

      {resource && (
        <>
          <Typography>
            You are invited to join <b>{resource.workspaceName as string}</b>.
          </Typography>

          <Divider className={style.separator} />

          <div>
            <Button disabled={isProcessing} onClick={handleBackToHomeScreen}>
              Reject
            </Button>
            &nbsp; &nbsp; &nbsp;
            <SuccessButton variant="contained" color="primary" disabled={isProcessing}>
              Accept
            </SuccessButton>
          </div>
        </>
      )}
    </div>
  );
};
